import { Layout, Menu, Tooltip } from "antd"
import React, { useCallback, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { ProjectSelect, Svg } from "~/components"
import "./styles.less"
import { useAuth, useProjectContext } from "~/hooks"
import { InfoCircleOutlined } from "@ant-design/icons"

const { Sider } = Layout

const CustomSider = ({ onSelect }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const currentPath = pathname
  const { isAdmin } = useAuth()
  const [openKeys, setOpenKeys] = useState([])
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { activeProject, setActiveProject } = useProjectContext()

  let menu = [
    {
      key: "(Dashboard)",
      title: "Bảng điều khiển",
      href: "/dashboard",
    },

    {
      key: "(Projects)",
      title: "Dự án",
      href: "/projects",
    },
    {
      key: "(Products)",
      title: "Sản phẩm",
      href: "/products",
    },
    {
      key: "(Procedures)",
      title: "Nhật ký sản xuất",
      href: "/diaries/procedures",
    },
    {
      key: "(Traceability)",
      title: "Truy xuất nguồn gốc",
      href: "/traceability",
    },
    {
      key: "(Supplies)",
      title: "Vật tư nông nghiệp",
      href: "/supplies",
    },
    {
      key: "(Users)",
      title: isAdmin ? "Người dùng" : "Thành viên",
      href: "/users",
    },
    {
      key: "(Enterprises)",
      title: "Doanh nghiệp",
      href: "/diaries/enterprises",
    },
    isAdmin && {
      key: "(Categories)",
      title: "Loại sản phẩm",
      href: "/categories",
    },
    isAdmin && {
      key: "(DeviceProfiles)",
      title: "Device Profiles",
      href: "/device-profiles",
    },
    isAdmin && {
      key: "(Devices)",
      title: "Thiết bị",
      href: "/devices",
    },
    isAdmin && {
      key: "(Functions)",
      title: "Chức năng",
      href: "/device-functions",
    },
    isAdmin && {
      key: "(Cameras)",
      title: "Camera",
      href: "/cameras",
    },
    {
      key: "(TermOfService)",
      title: "Demeter IoT Platform",
      href: "/term-of-service",
      hide: true,
    },
    {
      key: "(PrivatePolicy)",
      title: "Demeter IoT Platform",
      href: "/private-policy",
      hide: true,
    },
  ].filter(Boolean)

  useEffect(() => {
    const samePath = menu.find((item) => pathname.startsWith(item.href))

    if (samePath?.submenu?.length > 0) {
      const matched = samePath?.submenu?.find((item) => pathname.startsWith(item.href))

      if (matched) {
        onSelect(matched)
      }
    } else if (samePath) {
      onSelect(samePath)
    } else {
      onSelect(menu[0])
    }

    // const selected = menu.find((item) => item.key === openKeys[0])

    // console.log("Selected: ", pathname)

    // if (selected) {
    //   if (selected?.submenu?.length > 0) {
    //     const matched = selected?.submenu?.find((item) => pathname.startsWith(item.href))
    //     if (matched) {
    //       onSelect(matched)
    //     }
    //   } else {
    //     onSelect(selected)
    //   }
    // }
  }, [openKeys])

  const renderMenus = () => {
    return menu.map((item) => {
      if (item.hide) {
        return null
      }

      return item?.submenu
        ? {
            label: (
              <span
                className={`ml-[10px] inline-block text-[14px] text-[color:var(--mid-gray)] hover:text-primary-color`}
              >
                {item.title}
              </span>
            ),
            key: item.key,
            ...(item.icon && { icon: item.icon }),
            onTitleClick: ({ key }) => setOpenKeys([...(openKeys?.[0] === key ? [] : [key])]),
            icon: (
              <Svg
                name={item?.title?.toLowerCase()}
                fill={
                  (pathname === item.href || pathname.includes(item.href)) && "var(--primary-color)"
                }
                style={{ marginRight: 20 }}
              />
            ),
            children:
              !isCollapsed &&
              item.submenu
                ?.filter((i) => !!i?.title)
                .map((subItem) => {
                  return {
                    label: (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                          className={`ml-[4px] inline-block text-[14px] hover:text-primary-color ${
                            currentPath === subItem.href
                              ? "text-primary-color"
                              : "text-[color:var(--mid-gray)]"
                          }`}
                        >
                          {subItem.title}
                        </span>
                      </div>
                    ),
                    key: `${subItem.href}`,
                    onClick: () => subItem.href && history.push(subItem.href),
                    className: `${
                      currentPath === subItem.href
                        ? `!bg-[#0291471a] selected-submenu-item hidden`
                        : ""
                    }`,
                  }
                }),
          }
        : {
            label: (
              <span
                className={`text-[14px] hover:text-primary-color ${
                  currentPath === item.href
                    ? "selected-menu-item text-[color:var(--primary-color)]"
                    : "text-[color:var(--mid-gray)]"
                } ml-[10px] inline-block`}
              >
                {item.title}
              </span>
            ),
            key: item.key,
            icon: (
              <Svg
                name={item?.title?.toLowerCase()}
                fill={
                  (pathname === item.href || pathname.includes(item.href)) && "var(--primary-color)"
                }
                style={{ marginRight: 20 }}
              />
            ),
            className: currentPath?.includes(item.href || "#")
              ? "!bg-[#0291471a] !text-primary-color"
              : "",
            onClick: () => item.href && history.push(item.href),
            ...(item.icon && {
              icon: item.icon,
            }),
          }
    })
  }

  const getSelectedSubmenu = useCallback(() => {
    const keys = {
      "/users": function () {
        return "(Users)"
      },
      "/projects": function () {
        return "(Projects)"
      },
      "/devices": function () {
        return "(Devices)"
      },
      "/device-profiles": function () {
        return "(DeviceProfiles)"
      },
      "/categories": function () {
        return "(Categories)"
      },
      "/device-functions": function () {
        return "(Functions)"
      },
      "/cameras": function () {
        return "(Cameras)"
      },
      "/diaries/list": function () {
        return "(Diaries)"
      },
      "/diaries/procedures": function () {
        return "(Diaries)"
      },
      "/diaries/stages": function () {
        return "(Diaries)"
      },
      "/diaries/tasks": function () {
        return "(Diaries)"
      },
      "/diaries/traceability": function () {
        return "(Diaries)"
      },
      "/diaries/enterprises": function () {
        return "(Diaries)"
      },
      default: function () {
        return "()"
      },
    }

    return (keys[currentPath] || keys[`/admin${currentPath}`] || keys["default"])()
  }, [pathname])

  useEffect(() => {
    setOpenKeys([getSelectedSubmenu()])
  }, [currentPath, getSelectedSubmenu])

  return (
    <Sider
      className={`main-sider lg:!min-w-[250px] [&_.ant-layout-sider-zero-width-trigger]:!bg-[#029147] [&_.ant-layout-sider-zero-width-trigger]:!text-white [&_.ant-layout-sider-zero-width-trigger]:!shadow [&_.ant-layout-sider-zero-width-trigger]:lg:hidden [&_.ant-menu]:!border-none`}
      breakpoint="lg"
      collapsedWidth={"0"}
      theme={"light"}
      collapsible
      onCollapse={(collapsed) => {
        setIsCollapsed(collapsed)
      }}
    >
      <div className="site-brand">
        <div
          className="site-logo"
          style={{
            backgroundImage: `url('/assets/png/logo.png')`,
          }}
          onClick={() => {
            history.push("/")
          }}
        ></div>
        {!isAdmin && (
          <div className="flex w-full flex-col items-center justify-center gap-[12px] px-2">
            <div className="text-[16px]">
              Dự án hiện tại{" "}
              <Tooltip title="Chỉ lọc các dữ liệu thuộc về dự án được chọn.">
                <InfoCircleOutlined className="cursor-pointer pl-[4px] text-[12px]" />
              </Tooltip>
            </div>
            <ProjectSelect
              value={activeProject?.value}
              size={"large"}
              className={"w-full text-center"}
              popupClassName={"min-w-[300px]"}
              showSearch={false}
              placeholder="Tất cả"
              onChange={(project) => setActiveProject(project)}
            />
          </div>
        )}
      </div>

      <Menu theme={"light"} mode="inline" openKeys={openKeys} items={renderMenus()} />
    </Sider>
  )
}

export default CustomSider
