import React, { useCallback, useEffect } from "react"
import { Row, Col, Form, Input, Divider, Button, Popconfirm } from "antd"
import { DatePicker, ProjectSelect } from "~/components"
import { isEmpty, isEqual } from "lodash"
import { useAuth } from "~/hooks"
import { debounce } from "lodash"

const ProcedureForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const { user } = useAuth()
  const [form] = Form.useForm()

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = useCallback(
    debounce((values) => {
      const { project, stages, ...rest } = values
      onSubmit({
        ...rest,
        projectId: project?.value,
        stageIds: stages?.map((stage) => stage?.value)?.filter(Boolean),
      })
    }, 300),
    [],
  )

  return (
    <div>
      <Form
        layout={"vertical"}
        colon={false}
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Tên Quy trình"}
              name="name"
              rules={[{ required: true, message: "Tên quy trình là bắt buộc" }]}
            >
              <Input placeholder={"Nhập tên quy trình"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              rules={[{ required: true, message: "Dự án là bắt buộc" }]}
              label={"Dự án"}
              name="project"
            >
              <ProjectSelect onChange={(project) => form.setFieldsValue({ project })} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              label={"Ngày bắt đầu quy trình"}
              name="startDate"
              rules={[{ required: true, message: "Ngày bắt đầu quy trình là bắt buộc" }]}
            >
              <DatePicker className="w-full" />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Mô tả"} name="description">
              <Input.TextArea placeholder={"Nhập mô tả"} />
            </Form.Item>
          </Col>

          {/* <Col xs={24} lg={24}>
            <Form.Item label={"Giai đoạn"}>
              <Form.Item name={"stages"} hidden />
              <div>
                {watchStages?.length > 0 && (
                  <>
                    {watchStages?.map((stage, index) => (
                      <div
                        key={stage}
                        className="mb-[16px] flex w-full items-center justify-center gap-[16px]"
                      >
                        <StageSelect
                          excludes={watchStages?.map((stage) => stage?.value)}
                          value={stage}
                          onChange={(stage) => {
                            const newStages = [...watchStages]
                            newStages[index] = stage
                            form.setFieldValue(`stages`, newStages)
                          }}
                          className={"mb-[16px]"}
                          allowClear={false}
                        />
                        <MinusCircleOutlined
                          className="-translate-y-[8px] cursor-pointer"
                          onClick={() => {
                            const newStages = [...watchStages]
                            newStages.splice(index, 1)
                            form.setFieldValue(`stages`, newStages)
                          }}
                        />
                      </div>
                    ))}
                  </>
                )}
                <Button
                  type="primary"
                  onClick={() => {
                    if (last(watchStages) || !watchStages || watchStages?.length === 0) {
                      form.setFieldsValue({ stages: [...(watchStages || []), null] })
                    }
                  }}
                >
                  Thêm giai đoạn
                </Button>
              </div>
            </Form.Item>
          </Col> */}
        </Row>
        <Divider />
        <div className="flex">
          <Button loading={loading} type={"primary"} htmlType={"submit"}>
            Lưu
          </Button>
          <Button onClick={onCancel} disabled={loading} type={"outline"} style={{ marginLeft: 16 }}>
            Hủy
          </Button>
          {id &&
            ((user?.role === "user" && user?.id === initialValues?.creatorId) ||
              user?.role === "admin") && (
              <div className="grow text-end">
                <Popconfirm
                  title={"Xóa quy trình"}
                  description={" Bạn có chắc chắn muốn xóa quy trình này?"}
                  onConfirm={onRemove}
                >
                  <Button disabled={loading} danger>
                    Xóa quy trình
                  </Button>
                </Popconfirm>
              </div>
            )}
        </div>
      </Form>
    </div>
  )
}

export default ProcedureForm
