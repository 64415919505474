import React, { useState, useMemo, useEffect } from "react"
import { useApi } from "~/hooks"
import { useSetState } from "react-use"
import { PlusOutlined } from "@ant-design/icons"
import { useHistory, useLocation } from "react-router-dom"
import { Row, Col, Input, Button, message } from "antd"
import { Table } from "~/components"
import { debounce } from "lodash"
import dayjs from "dayjs"
import { createProduct } from "~/services/apis"
import queryString from "query-string"
import useProduct from "~/hooks/useProduct"

const DEFAULT_PARAMS = {
  search: "",
  page: 1,
  limit: 10,
}

const ProductsPage = () => {
  const history = useHistory()
  const location = useLocation()
  const qs = queryString.parse(location.search)
  const [params, setParams] = useSetState(Object.keys(qs)?.length > 0 ? qs : { ...DEFAULT_PARAMS })
  const [loading, setLoading] = useState(false)
  const api = `
  query products($params: FetchDto!) {
      products(fetch: $params) {
        id
        name
        description
        imageUrls
        creator {
          id
          firstName
          lastName
        }
        createdAt
      }
    }
  `
  const response = useApi(
    api,
    {
      params: {
        ...params,
        limit: params?.limit && +params.limit,
        page: params?.page && +params.page,
      },
    },
    {
      revalidateOnFocus: true,
    },
  )

  useEffect(
    () => history.replace(`${location.pathname}?${queryString.stringify(params)}`),
    // eslint-disable-next-line
    [params],
  )

  const parsedData = useMemo(() => {
    const { data, error } = response
    const headers = data?.headers

    return {
      error,
      data: data?.data?.products,
      meta: (headers && JSON.parse(headers.get("meta"))) || {},
    }
  }, [response])

  const columns = [
    {
      title: "Thông tin sản phẩm",
      key: "id",
      dataIndex: "id",
      width: 250,
      className: "c-p",
      render: (value, record) => (
        <Row gutter={0} style={{ flexDirection: "row", width: 400 }}>
          <Col style={{ flex: 1, display: "grid" }}>
            <img
              alt={"device-thumbnail"}
              className="h-[70px] w-[70px] rounded-[10px] object-contain shadow"
              src={record?.imageUrls?.[0] || "/assets/png/empty-1.png"}
            />
          </Col>
          <Col style={{ flex: 4 }}>
            <div style={{ fontWeight: 600, color: "var(--black)" }}>{record?.name}</div>
          </Col>
        </Row>
      ),
    },

    {
      title: "Ngày tạo",
      key: "createdAt",
      dataIndex: "createdAt",
      className: "c-p",
      render: function render(value) {
        return value ? dayjs(value).format("DD-MM-YYYY") : "-"
      },
    },
  ]

  const debounceChange = debounce((search) => {
    setParams({ search, page: 1 })
  }, 500)

  const onCreateProduct = async (values) => {
    try {
      setLoading(true)
      await createProduct(values)
      message.success("Tạo sản phẩm thành công!")

      response.mutate(api)
      hideProductDrawer()
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  const {
    show: showProductDrawer,
    hide: hideProductDrawer,
    ProductDrawer,
  } = useProduct({
    loading,
    onSubmit: onCreateProduct,
  })

  return (
    <div>
      <Row style={{ marginBottom: 16 }} justify={"space-between"}>
        <Col span={10}>
          <Input
            defaultValue={params?.search}
            onChange={(e) => debounceChange(e.target.value)}
            placeholder={"Tìm kiếm theo tên....."}
            allowClear={true}
          />
        </Col>
        <Col>
          <Button onClick={showProductDrawer} type={"primary"}>
            <PlusOutlined />
            Thêm sản phẩm
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            loading={response.isValidating}
            rowKey={"id"}
            scroll={{
              x: columns.reduce((acc, col) => {
                acc += col.width || 100

                return acc
              }, 300),
              scrollToFirstRowOnChange: true,
            }}
            columns={columns}
            dataSource={parsedData?.data}
            onRow={(record) => ({
              onClick: () => history.push(`/products/${record.id}`),
            })}
            pagination={{
              total: parsedData?.meta?.totalDocs,
              pageSize: params.limit,
              showSizeChanger: false,
              hideOnSinglePage: true,
              onChange: (page) => setParams({ page }),
              current: Number(params?.page || 1),
            }}
          />
        </Col>
      </Row>
      <ProductDrawer />
    </div>
  )
}

export default ProductsPage
