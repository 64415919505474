import React, { useEffect, useState } from "react"
import { Select, Spin } from "antd"
import { useSetState } from "react-use"
import { debounce } from "lodash"
import { getSupplies } from "~/services/apis"

const DEFAULT_PARAMS = {
  limit: 10,
  page: 1,
  search: "",
}

const SupplySelect = ({
  className,
  multiple = false,
  value = null,
  onChange = () => {},
  excludes = [],
  allowClear = true,
  ...props
}) => {
  const [params, setParams] = useSetState({ ...DEFAULT_PARAMS })
  const [supplies, setSupplies] = useState([])
  const [meta, setMeta] = useState({})
  const [loading, setLoading] = useState(false)

  const appendData = async () => {
    setLoading(true)
    try {
      const { data, headers } = await getSupplies(params)

      setMeta((headers && JSON.parse(headers.get("meta"))) || {})
      setSupplies([
        ...(params.page === 1 ? [] : supplies),
        ...(data?.supplies || [])
          ?.filter((supply) => !excludes.includes(supply?.id))
          ?.map((supply) => {
            return {
              label: supply?.name,
              value: supply?.id,
            }
          }),
      ])
    } catch (e) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    appendData()
    // eslint-disable-next-line
  }, [params])

  const onScroll = (e) => {
    if (
      !loading &&
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight
    ) {
      params.page < meta?.totalPages && setParams({ page: params.page + 1 })
    }
  }

  return (
    <Select
      labelInValue
      value={value}
      showSearch={true}
      notFoundContent={"Không tìm thấy vật tư nông nghiệp"}
      onPopupScroll={onScroll}
      onSearch={debounce((search) => setParams({ search, page: 1 }), 300)}
      mode={multiple && "multiple"}
      options={supplies}
      onChange={onChange}
      allowClear={allowClear}
      filterOption={false}
      dropdownRender={(menu) => (
        <>
          {menu}
          {loading && (
            <div className="place-center" style={{ padding: "8px 0" }}>
              <Spin />
            </div>
          )}
        </>
      )}
      onClear={() => onChange(null)}
      className={className}
      placeholder="Chọn vật tư nông nghiệp"
      {...props}
    />
  )
}

export default SupplySelect
