import { App, Button, Col, Divider, Row, Table } from "antd"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSetState } from "react-use"
import { useApi } from "~/hooks"
import queryString from "query-string"
import dayjs from "dayjs"
import { useHistory, useLocation } from "react-router-dom"
import {
  createTraceability,
  mySpace,
  removeTraceability,
  updateTraceability,
} from "~/services/apis"
import useTraceability from "~/hooks/useTraceability"

const DEFAULT_PARAMS = {
  search: "",
  page: 1,
  limit: 20,
}

function TraceabilityPage() {
  const history = useHistory()
  const location = useLocation()
  const qs = queryString.parse(location.search)
  const [params, setParams] = useSetState(Object.keys(qs)?.length > 0 ? qs : { ...DEFAULT_PARAMS })
  const viewTraceabilityRef = useRef(null)
  const { message } = App.useApp()
  const [space, setSpace] = useState(null)
  const [loadingSpace, setLoadingSpace] = useState(false)

  const fetchMySpace = async () => {
    try {
      setLoadingSpace(true)
      const response = await mySpace()

      if (response?.mySpace) {
        setSpace(response.mySpace)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingSpace(false)
    }
  }

  useEffect(() => {
    fetchMySpace()
  }, [])

  const api = `
    query traceabilities($params: FetchDto!) {
        traceabilities(fetch: $params) {
          id
          date
          note
          productId
          product {
            id
            name
          }
          procedureId
          procedure {
            id
            name
          }
          batchId
          startNumber
          endNumber
          groupId
          group {
            id
            name
          }
          quantity
          unit
          manufactureDate
          expiredDate
          createdAt
          updatedAt
        }
      }
  `

  const response = useApi(
    api,
    {
      params: {
        ...params,
        limit: params?.limit && +params.limit,
        page: params?.page && +params.page,
      },
    },
    {
      revalidateOnFocus: true,
    },
  )

  useEffect(
    () => history.replace(`${location.pathname}?${queryString.stringify(params)}`),
    // eslint-disable-next-line
    [params],
  )

  const parsedData = useMemo(() => {
    const { data, error } = response
    const headers = data?.headers

    return {
      error,
      data: data?.data?.traceabilities,
      meta: (headers && JSON.parse(headers.get("meta"))) || {},
    }
  }, [response])

  const onTraceabilitySubmit = async (values) => {
    try {
      let result

      if (viewTraceabilityRef.current) {
        result = await updateTraceability(viewTraceabilityRef.current.id, values)
      } else {
        result = await createTraceability(values)
      }

      if (result.createTraceability) {
        message.success("Tạo truy xuất thành công!")
        viewTraceabilityRef.current = null
        hideTraceabilityDrawer()

        response.mutate()
      }

      if (result.updateTraceability) {
        message.success("Cập nhật truy xuất thành công!")
        viewTraceabilityRef.current = null
        hideTraceabilityDrawer()

        response.mutate()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onTraceabilityRemove = async () => {
    try {
      const result = await removeTraceability(viewTraceabilityRef.current.id)

      if (result.removeTraceability) {
        message.success("Xóa truy xuất thành công!")
        viewTraceabilityRef.current = null
        hideTraceabilityDrawer()

        response.mutate()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: "Truy xuất",
      key: "name",
      dataIndex: "name",
      width: 400,
      className: "c-p",
      render: (_, record) => {
        return (
          <>
            <div>
              <div>Sản phẩm: </div>
              <div className="font-semibold">{record?.product?.name}</div>

              <div>Quy trình canh tác: </div>
              <div className="font-semibold">{record?.procedure?.name}</div>
            </div>
          </>
        )
      },
    },

    {
      title: "Khu vực",
      key: "group",
      dataIndex: "group",
      className: "c-p",
      render: (value) => {
        return value ? value?.name : "-"
      },
    },
    {
      title: "Ngày truy xuất",
      key: "date",
      dataIndex: "date",
      className: "c-p",
      render: (value) => {
        return value ? dayjs(value).format("DD-MM-YYYY") : "-"
      },
    },
    {
      title: "Mô tả",
      key: "description",
      dataIndex: "description",
      className: "c-p",
      render: (value) => {
        return value ? value : "-"
      },
    },
    {
      title: "Ngày tạo",
      key: "createdAt",
      dataIndex: "createdAt",
      className: "c-p",
      render: function render(value) {
        return value ? dayjs(value).format("DD-MM-YYYY") : "-"
      },
    },
    {
      title: "",
      key: "preview",
      dataIndex: "preview",
      className: "c-p",
      render: function render(_, record) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <a href={`/traceability/client/${record?.id}`}>Xem trước</a>
          </div>
        )
      },
    },
  ]

  const {
    show: showTraceabilityDrawer,
    hide: hideTraceabilityDrawer,
    TraceabilityDrawer,
  } = useTraceability({
    traceability: viewTraceabilityRef.current,
    onSubmit: onTraceabilitySubmit,
    onRemove: onTraceabilityRemove,
    onClose: () => {
      viewTraceabilityRef.current = null
    },
  })

  return (
    <div>
      <div className="text-end">
        <Button
          loading={loadingSpace}
          type="primary"
          onClick={() => {
            if (!space?.businessCode) {
              message.warning(
                "Vui lòng nhập đầy đủ thông tin doanh nghiệp trước khi tạo truy xuất!",
              )
              return
            }

            showTraceabilityDrawer()
          }}
        >
          Tạo truy xuất
        </Button>
        <Divider />
      </div>

      <Row>
        <Col span={24}>
          <Table
            loading={response.isValidating}
            rowKey={"id"}
            columns={columns}
            scroll={{
              x: columns.reduce((acc, col) => {
                acc += col.width || 100

                return acc
              }, 300),
              scrollToFirstRowOnChange: true,
            }}
            dataSource={parsedData?.data}
            onRow={(record) => ({
              onClick: () => {
                viewTraceabilityRef.current = {
                  ...record,
                  product: {
                    label: record?.product?.name,
                    value: record?.product?.id,
                  },
                  procedure: {
                    label: record?.procedure?.name,
                    value: record?.procedure?.id,
                  },
                  group: {
                    label: record?.group?.name,
                    value: record?.group?.id,
                  },
                  enterprise: {
                    label: `${record?.enterprise?.businessCode} / ${record?.enterprise?.businessName}`,
                    value: record?.enterprise?.id,
                  },
                  date: record?.date && dayjs(record.date),
                  manufactureDate: record?.manufactureDate && dayjs(record.manufactureDate),
                  expiredDate: record?.expiredDate && dayjs(record.expiredDate),
                }

                showTraceabilityDrawer()
              },
            })}
            pagination={{
              total: parsedData?.meta?.totalDocs,
              pageSize: params.limit,
              showSizeChanger: false,
              hideOnSinglePage: true,
              onChange: (page) => setParams({ page }),
              current: Number(params?.page || 1),
            }}
          />
        </Col>
      </Row>
      <TraceabilityDrawer />
    </div>
  )
}

export default TraceabilityPage
