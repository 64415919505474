import React, { useState, useCallback, useMemo } from "react"
import { Drawer } from "antd"
import { StageTaskForm } from "~/components"

const useStageTask = ({ stageTask, loading = false, width = "80%", onClose, onSubmit } = {}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const onCancel = () => {
    onClose && onClose()
    hide()
  }

  const initialValues = useMemo(() => {
    return {
      ...stageTask,
    }
  }, [stageTask])

  const StageTaskDrawer = useCallback(() => {
    return (
      <Drawer
        width={width}
        closeIcon={null}
        keyboard={false}
        title={"Tiến độ công việc"}
        open={visible}
        onClose={onCancel}
      >
        <StageTaskForm
          id={stageTask?.id}
          initialValues={initialValues}
          onSubmit={onSubmit}
          onCancel={onCancel}
          loading={loading}
        />
      </Drawer>
    )
    // eslint-disable-next-line
  }, [visible])

  return {
    visible,
    show,
    hide,
    StageTaskDrawer,
  }
}

export default useStageTask
