import React, { useEffect } from "react"
import {
  Row,
  Col,
  Form,
  Input,
  Divider,
  Button,
  Popconfirm,
  InputNumber,
  Select,
  Tag,
  AutoComplete,
  message,
} from "antd"
import { isEmpty, isEqual, last } from "lodash"
import { DatePicker, SupplySelect, Upload } from ".."
import { MinusCircleOutlined } from "@ant-design/icons"
import { PRODUCT_UNIT } from "~/constants"
import { useAuth } from "~/hooks"

const TaskForm = ({
  id,
  loading,
  initialValues,
  readOnly,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const { user } = useAuth()
  const [form] = Form.useForm()
  // const watchTaskSupplies = Form.useWatch("taskSupplies", form)
  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = (values) => {
    const { taskSupplies, ...rest } = values

    if (
      taskSupplies?.length > 0 &&
      taskSupplies?.find((tS) => !tS?.supply || !tS?.quantity || !tS?.unit)
    ) {
      message.error("Thông tin vật tư nông nghiệp sử dụng không được để trống!")
      return
    }

    onSubmit({
      ...rest,
      ...(taskSupplies?.length > 0 && {
        taskSupplies: taskSupplies.map((tS) => ({
          supplyId: tS.supply?.value,
          quantity: tS.quantity,
          unit: tS.unit,
        })),
      }),
    })
  }

  return (
    <div>
      <Form
        colon={false}
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
        disabled={readOnly}
      >
        <Row gutter={20}>
          <Col xs={24} lg={24}>
            <Form.Item label={"Hình ảnh mô tả"} name="imageUrls">
              <Upload multiple />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              label={"Tên công việc"}
              name="name"
              rules={[{ required: true, message: "Tên công việc là bắt buộc!" }]}
            >
              <Input placeholder={"Nhập tên công việc"} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              label={"Mức độ ưu tiên"}
              name="priority"
              rules={[{ required: true, message: "Mức độ ưu tiên là bắt buộc" }]}
            >
              <Select
                options={[
                  {
                    label: <Tag color="blue">Thông thường</Tag>,
                    value: "common",
                  },
                  {
                    label: <Tag color="gold">Ưu tiên</Tag>,
                    value: "high",
                  },
                  {
                    label: <Tag color="red">Quan trọng</Tag>,
                    value: "important",
                  },
                ]}
                placeholder={"Chọn mức độ ưu tiên"}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              label={"Ngày dự kiến thực hiện"}
              name="plannedStartedAt"
              rules={[{ required: true, message: "Ngày dự kiến thực hiện là bắt buộc" }]}
            >
              <DatePicker className="w-full" />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item
              label={"Số ngày thực hiện"}
              name="executionDays"
              rules={[{ required: true, message: "Số ngày thực hiện là bắt buộc" }]}
            >
              <InputNumber
                min={0}
                suffix={<span className="inline-block pr-4 text-gray-500">Ngày</span>}
                className="w-full"
                placeholder={"Nhập số ngày thực hiện"}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={24}>
            <Form.Item label={"Mô tả"} name="description">
              <Input.TextArea placeholder={"Nhập mô tả"} rows={3} />
            </Form.Item>
          </Col>

          {/* <Col xs={24} lg={24}>
            <Form.Item label={"Vật tư nông nghiệp"}>
              <Form.Item name={"taskSupplies"} hidden />
              <div>
                <Form.List name="taskSupplies">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restFields }) => (
                        <Row key={key} gutter={16} className="mb-[16px] w-full">
                          <Col span={8}>
                            <Form.Item {...restFields} name={[name, "supply"]} noStyle>
                              <SupplySelect allowClear={false} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item {...restFields} name={[name, "quantity"]} noStyle>
                              <InputNumber
                                min={0}
                                className="w-full"
                                placeholder="Số lượng sử dụng"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item {...restFields} name={[name, "unit"]} noStyle>
                              <AutoComplete
                                options={PRODUCT_UNIT.map((unit) => ({ label: unit, value: unit }))}
                              >
                                <Input placeholder="Đơn vị tính" />
                              </AutoComplete>
                            </Form.Item>
                          </Col>
                          <Col span={1} className="flex items-center">
                            <MinusCircleOutlined
                              className="cursor-pointer"
                              onClick={() => remove(name)}
                            />
                          </Col>
                        </Row>
                      ))}
                      {!readOnly && (
                        <Button
                          type="primary"
                          onClick={() => {
                            if (
                              last(watchTaskSupplies)?.supply ||
                              !watchTaskSupplies ||
                              watchTaskSupplies?.length === 0
                            ) {
                              add()
                            }
                          }}
                        >
                          Thêm vật tư nông nghiệp
                        </Button>
                      )}
                    </>
                  )}
                </Form.List>
              </div>
            </Form.Item>
          </Col> */}
        </Row>
        {!readOnly && (
          <>
            <Divider />
            <Row style={{ alignItems: "center" }} justify="space-between">
              <div>
                <Button loading={loading} type={"primary"} htmlType={"submit"}>
                  Lưu
                </Button>
                <Button
                  onClick={onCancel}
                  disabled={loading}
                  type={"outline"}
                  style={{ marginLeft: 16 }}
                >
                  Hủy
                </Button>
              </div>
              {id && user?.role !== "employee" && (
                <div>
                  <Popconfirm
                    title={"Bạn có chắc chắn muốn xóa công việc này ?"}
                    onConfirm={onRemove}
                  >
                    <Button loading={loading} danger style={{ marginLeft: 16 }}>
                      Xóa công việc
                    </Button>
                  </Popconfirm>
                </div>
              )}
            </Row>
          </>
        )}
      </Form>
    </div>
  )
}

export default TaskForm
