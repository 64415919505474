import React, { useCallback, useEffect } from "react"
import { Row, Col, Form, Input, Divider, Button, Popconfirm, InputNumber } from "antd"
import { isEmpty, isEqual } from "lodash"
import GroupSelect from "../GroupSelect"
import ProcedureSelect from "../ProcedureSelect"
import ProductSelect from "../ProductSelect"
import { DatePicker } from "../DatePicker"
import { useAuth } from "~/hooks"
import { QRCodeSVG } from "qrcode.react"
import { DownloadOutlined } from "@ant-design/icons"
import { debounce } from "lodash"

const TraceabilityForm = ({
  id,
  loading,
  initialValues,
  onSubmit = () => {},
  onRemove = () => {},
  onCancel = () => {},
}) => {
  const { user } = useAuth()
  const [form] = Form.useForm()

  useEffect(() => {
    if (!isEmpty(initialValues) && !isEqual(form.getFieldValue(), initialValues)) {
      form.resetFields()
    }
  }, [form, initialValues])

  const onFinish = useCallback(
    debounce((values) => {
      const { tasks, group, product, procedure, enterprise, ...rest } = values

      onSubmit({
        ...rest,
        productId: product?.value,
        procedureId: procedure?.value,
        groupId: group?.value,
        enterpriseId: enterprise?.value,
        taskIds: tasks?.map((task) => task?.value)?.filter(Boolean),
      })
    }, 300),
    [],
  )

  const downloadQRCode = () => {
    const svg = document.getElementById(`qr-code-${id}`)
    const svgData = new XMLSerializer().serializeToString(svg)
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    const img = new Image()
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)
      const pngFile = canvas.toDataURL("image/png")
      const downloadLink = document.createElement("a")
      downloadLink.download = `qr-code-${id}.png`
      downloadLink.href = pngFile
      downloadLink.click()
    }
    img.src = "data:image/svg+xml;base64," + btoa(svgData)
  }

  return (
    <div>
      <Form
        colon={false}
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinishFailed={(e) => console.log(e)}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          {id && (
            <div className="mb-2 flex w-full flex-col items-center justify-center">
              <QRCodeSVG value={`https://user.demeter.vn/traceability/client/${id}`} />
              <QRCodeSVG
                id={`qr-code-${id}`}
                size={480}
                className="hidden"
                value={`https://user.demeter.vn/traceability/client/${id}`}
              />
              <Button type={"link"} icon={<DownloadOutlined />} onClick={downloadQRCode}>
                Tải xuống
              </Button>
            </div>
          )}
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Mã lô sản xuất"}
              name="batchId"
              rules={[{ required: true, message: "Mã lô sản xuất là bắt buộc!" }]}
            >
              <Input placeholder={"Nhập mã lô sản xuất"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Ngày truy xuất"}
              name="date"
              rules={[{ required: true, message: "Ngày truy xuất là bắt buộc!" }]}
            >
              <DatePicker className={"w-full"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Số bắt đầu"}
              name="startNumber"
              rules={[{ required: true, message: "Số bắt đầu là bắt buộc!" }]}
            >
              <Input placeholder={"Nhập số bắt đầu"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Số kết thúc"}
              name="endNumber"
              rules={[{ required: true, message: "Số kết thúc là bắt buộc!" }]}
            >
              <Input placeholder={"Nhập số kết thúc"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item
              label={"Khu vực"}
              name="group"
              rules={[{ required: true, message: "Khu vực là bắt buộc!" }]}
            >
              <GroupSelect onChange={(group) => form.setFieldsValue({ group })} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Sản phẩm"}
              name="product"
              rules={[{ required: true, message: "Sản phẩm là bắt buộc!" }]}
            >
              <ProductSelect onChange={(product) => form.setFieldsValue({ product })} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Quy trình canh tác"}
              name="procedure"
              rules={[{ required: true, message: "Quy trình canh tác là bắt buộc!" }]}
            >
              <ProcedureSelect onChange={(procedure) => form.setFieldsValue({ procedure })} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={"Sản lượng"}
              name="quantity"
              rules={[{ required: true, message: "Sản lượng là bắt buộc!" }]}
            >
              <InputNumber min={0} className="w-full" placeholder={"Nhập sản lượng"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Ngày sản xuất"}
              name="manufactureDate"
              rules={[{ required: true, message: "Ngày sản xuất là bắt buộc!" }]}
            >
              <DatePicker className={"w-full"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={"Ngày hết hạn"}
              name="expiredDate"
              rules={[{ required: true, message: "Ngày hết hạn là bắt buộc!" }]}
            >
              <DatePicker className={"w-full"} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={24}>
            <Form.Item label={"Mô tả"} name="note">
              <Input.TextArea placeholder={"Nhập mô tả"} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row style={{ alignItems: "center" }} justify="space-between">
          <div>
            <Button loading={loading} type={"primary"} htmlType={"submit"}>
              Lưu
            </Button>
            <Button
              onClick={onCancel}
              disabled={loading}
              type={"outline"}
              style={{ marginLeft: 16 }}
            >
              Hủy
            </Button>
          </div>
          {id && user?.role !== "employee" && (
            <div>
              <Popconfirm title={"Bạn có chắc chắn muốn xóa truy xuất này ?"} onConfirm={onRemove}>
                <Button loading={loading} danger style={{ marginLeft: 16 }}>
                  Xóa truy xuất
                </Button>
              </Popconfirm>
            </div>
          )}
        </Row>
      </Form>
    </div>
  )
}

export default TraceabilityForm
