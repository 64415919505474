import React from "react"
import { Form, Input, Row, Col, Checkbox, Button } from "antd"
import { useHistory } from "react-router-dom"

import "./styles.less"

export const LoginHeader = ({ login, loading }) => {
  const history = useHistory()
  const [form] = Form.useForm()

  const onLogin = (values) => {
    const { username, password } = values
    login({ username, password })
  }

  return (
    <div className="login-header-wrapper">
      <Row className="login-header container" justify={"space-between"}>
        <Col span={12} className="logo-wrapper">
          <Col
            flex={2}
            style={{
              alignSelf: "center",
              fontSize: 28,
              fontWeight: 900,
              color: "var(--white)",
              cursor: "pointer",
            }}
            onClick={() => history.push("/")}
          >
            DEMETER
          </Col>
          <Col className="link-news opacity-0">
            <a href={"/"}>Giải pháp</a>
          </Col>
          <Col className="link-news opacity-0">
            <a href={"/"}>Khách hàng</a>
          </Col>
          <Col className="link-news opacity-0">
            <a href={"/"}>Thiết bị & dự án</a>
          </Col>
          <Col className="link-news opacity-0">
            <a href="/">Tin tức</a>
          </Col>
        </Col>
        <Col span={9} className={"login-section"}>
          <Form
            layout={"vertical"}
            form={form}
            name="basic"
            className={"form-wrapper"}
            onFinish={(values) =>
              values?.username && values?.password?.length >= 6 && onLogin(values)
            }
          >
            <div className="login-form">
              <Row className="form-group" style={{ marginLeft: 0 }}>
                <Form.Item
                  className={"form-item"}
                  label={<span className="title">Email/ Số điện thoại</span>}
                  name="username"
                  rules={[{ required: true, message: "Vui lòng nhập email/số điện thoại" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item initialValue={true} name="remember" valuePropName="checked" noStyle>
                  <Checkbox className="remember-wrapper">Ghi nhớ</Checkbox>
                </Form.Item>
              </Row>
              <Row className="form-group" style={{ justifyContent: "flex-start" }}>
                <Form.Item
                  className={"form-item"}
                  name="password"
                  label={<span className="title">Mật khẩu</span>}
                  rules={[{ required: true, message: "Vui lòng nhập mật khẩu" }]}
                >
                  <Input type="password" />
                </Form.Item>
                <a href="/forgot-password">Quên mật khẩu?</a>
              </Row>
              <Row className="form-group" style={{ alignSelf: "center" }}>
                <Button
                  htmlType={"submit"}
                  className="login-btn"
                  type="default"
                  style={{ alignSelf: "center" }}
                  loading={loading}
                >
                  Đăng nhập
                </Button>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default LoginHeader
