import {
  ArrowLeftOutlined,
  CommentOutlined,
  LoadingOutlined,
  OrderedListOutlined,
  PlusOutlined,
  DotChartOutlined,
  MinusOutlined,
} from "@ant-design/icons"
import { Avatar, Button, Divider, Image, List, Modal, Tabs } from "antd"
import dayjs from "dayjs"
import vi from "dayjs/locale/vi"
import relativeTime from "dayjs/plugin/relativeTime"
import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import { StageTaskForm, UserTag } from "~/components"
import CommentForm from "~/components/CommentForm"
import { createComment, getStageTask, updateStageTask } from "~/services/apis"
import { getAvatarBackgroundColor } from "~/utils"
import { pick } from "lodash"
import TaskTag from "~/components/TaskTag"

dayjs.extend(relativeTime)
dayjs.locale(vi)

function EmployeeTaskDetail() {
  const [selected, setSelected] = useState("information")
  const history = useHistory()
  const { stageTaskId } = useParams()
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [stageTask, setStageTask] = useState(null)
  const [commenting, setCommenting] = useState(false)
  const [commentModalOpen, setCommentModalOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState("taskProgress")

  const onCreateComment = async (values) => {
    try {
      setCommenting(true)

      const result = await createComment({
        ...values,
        stageTaskId,
      })

      if (result?.createComment) {
        fetchStageTask()
        setCommentModalOpen(false)
        setCommenting(false)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setCommenting(false)
    }
  }

  const fetchStageTask = async () => {
    try {
      setLoading(true)
      const response = await getStageTask(stageTaskId)

      if (response?.stageTask) {
        setStageTask(response.stageTask)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (values) => {
    try {
      setSubmitting(true)

      const response = await updateStageTask(stageTaskId, {
        imageUrls: values.imageUrls && values?.imageUrls,
        startedAt: values.startedAt && values.startedAt?.toISOString(),
        completedAt: values.completedAt && values.completedAt?.toISOString(),
        note: values.note,
        stageTaskSupplies:
          values.stageTaskSupplies?.length > 0 ? values.stageTaskSupplies : undefined,
      })

      if (response?.updateStageTask) {
        fetchStageTask()
        window.scrollTo({ top: 0, behavior: "smooth" })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    fetchStageTask()
  }, [])

  return (
    <div className="relative mx-auto min-h-screen w-full max-w-lg bg-gray-100 shadow-lg">
      {loading && (
        <div className="absolute z-[20] grid h-full w-full place-items-center bg-black/60">
          <LoadingOutlined className="text-[48px] text-primary-color" />
        </div>
      )}
      <div className="relative z-[10]">
        <div className="sticky left-0 right-0 top-0 z-40 flex h-16 flex-row items-center justify-between bg-[#3a2b2a] pl-5 pr-10 shadow">
          <ArrowLeftOutlined
            className="cursor-pointer text-[20px] text-white"
            onClick={() => history.push("/employee/dashboard")}
          />
          <h1 className="text-[16px] font-medium text-white">Quản lý công việc</h1>
        </div>

        {selected === "information" && (
          <div className="m-3 rounded-lg border bg-white p-3 shadow-lg">
            <div className="text-gray-500">
              <span className="text-[15px] font-semibold">
                {stageTask?.procedureStage?.procedure?.name}
              </span>
              <MinusOutlined className="px-2 text-[12px]" />
              <span className="text-[13px]">{stageTask?.procedureStage?.stage?.name}</span>
            </div>
            <div className="h-[20px]" />
            <Tabs
              items={[
                {
                  label: "Tiến độ xử lý",
                  key: "taskProgress",
                },
                {
                  label: "Chi tiết công việc",
                  key: "taskDetail",
                },
              ]}
              size="large"
              activeKey={selectedTab}
              onChange={(key) => setSelectedTab(key)}
              centered
            />
            {selectedTab === "taskProgress" && (
              <StageTaskForm
                employeeMode
                onSubmit={onSubmit}
                loading={submitting}
                initialValues={{
                  ...stageTask,
                  startedAt: stageTask?.startedAt && dayjs(stageTask?.startedAt),
                  completedAt: stageTask?.completedAt && dayjs(stageTask?.completedAt),
                  plannedStartedAt:
                    stageTask?.plannedStartedAt && dayjs(stageTask?.plannedStartedAt),
                  plannedCompletedAt:
                    stageTask?.plannedCompletedAt && dayjs(stageTask?.plannedCompletedAt),
                  assignedTo: stageTask?.assignedTo
                    ? {
                        label: (
                          <span>
                            {stageTask?.assignedTo?.firstName}
                            &nbsp;{stageTask?.assignedTo?.lastName} /&nbsp;
                            <UserTag value={stageTask?.assignedTo?.role} />
                          </span>
                        ),
                        value: stageTask?.assignedTo?.id,
                      }
                    : undefined,
                  stageTaskSupplies: stageTask?.stageTaskSupplies?.map((stageTaskSupply) => ({
                    ...stageTaskSupply,
                    supply: {
                      label: stageTaskSupply?.supply?.name,
                      value: stageTaskSupply?.supply?.id,
                    },
                  })),
                }}
              />
            )}
            {selectedTab === "taskDetail" && (
              <div className="mb-[16px] flex flex-col gap-4">
                <div className="flex w-full flex-row items-baseline justify-between">
                  <span>Tên công việc</span>
                  <span className="text-[15px] font-medium">{stageTask?.task?.name}</span>
                </div>
                <Divider className="m-0 p-0" />
                <div className="flex w-full flex-row items-baseline justify-between">
                  <span>Mức độ ưu tiên</span>
                  <span>
                    <TaskTag value={stageTask?.task?.priority} />
                  </span>
                </div>
                <Divider className="m-0 p-0" />

                <div className="flex w-full flex-row items-baseline justify-between">
                  <span>Ngày dự kiến thực hiện</span>
                  <span className="text-[15px] font-medium">
                    {stageTask?.plannedStartedAt
                      ? dayjs(stageTask?.plannedStartedAt).format("DD/MM/YYYY")
                      : "Chưa cập nhật"}
                  </span>
                </div>
                <Divider className="m-0 p-0" />

                <div className="flex w-full flex-row items-baseline justify-between">
                  <span>Ngày dự kiến hoàn thành</span>
                  <span className="text-[15px] font-medium">
                    {stageTask?.plannedCompletedAt
                      ? dayjs(stageTask?.plannedCompletedAt).format("DD/MM/YYYY")
                      : "Chưa cập nhật"}
                  </span>
                </div>
                <h2 className="mb-0">Hình ảnh mô tả</h2>
                <div className="-mx-2 mt-[10px] flex flex-wrap gap-2 pl-[32px] [&_.ant-image-mask]:rounded">
                  {stageTask?.task?.imageUrls?.length > 0 && (
                    <Image.PreviewGroup>
                      {stageTask?.task?.imageUrls?.map((image) => (
                        <Image
                          key={image}
                          width={75}
                          height={75}
                          className="rounded object-cover"
                          src={image}
                        />
                      ))}
                    </Image.PreviewGroup>
                  )}
                  {stageTask?.task?.imageUrls?.length === 0 && (
                    <div className="grid w-full place-items-center">Đang cập nhật</div>
                  )}
                </div>

                <h2 className="m-0">Mô tả công việc</h2>
                <div
                  className="ck-content relative"
                  dangerouslySetInnerHTML={{
                    __html: stageTask?.task?.description || "Đang cập nhật",
                  }}
                />
              </div>
            )}
          </div>
        )}

        {selected === "comments" && (
          <div className="m-3 rounded-lg border bg-white p-3 shadow-lg">
            <h2 className="mt-1">Thảo luận</h2>
            <div>
              <Button
                size="small"
                className="m-0 p-0"
                type={"link"}
                onClick={() => setCommentModalOpen(true)}
              >
                <PlusOutlined /> Thêm thảo luận
              </Button>

              <Modal
                footer={null}
                open={commentModalOpen}
                onCancel={() => setCommentModalOpen(false)}
              >
                <CommentForm
                  initialValues={{
                    content: "",
                    imageUrls: [],
                  }}
                  onCancel={() => setCommentModalOpen(false)}
                  loading={commenting}
                  onSubmit={onCreateComment}
                />
              </Modal>
            </div>

            {stageTask?.comments?.length === 0 && (
              <div className="grid h-[100px] place-items-center">Không có thảo luận</div>
            )}

            <div className="mt-[32px]">
              {stageTask?.comments?.length > 0 &&
                stageTask?.comments?.map((comment, index) => (
                  <div key={comment?.id}>
                    {index > 0 && <Divider />}
                    <div className="flex w-full justify-between">
                      <div className="flex items-center gap-[6px]">
                        <Avatar
                          style={{
                            backgroundColor: getAvatarBackgroundColor(
                              comment?.creator?.firstName?.[0],
                            ),
                          }}
                          size={"small"}
                          className="mr-[4px]"
                        >
                          {comment?.creator?.firstName?.[0] || ""}
                        </Avatar>
                        <div className="line-clamp-1 font-semibold">{`${comment?.creator?.firstName} ${comment?.creator?.lastName}`}</div>
                      </div>
                      <div>{dayjs(comment?.createdAt).fromNow()}</div>
                    </div>
                    <div className="mt-[8px] pl-[32px]">{comment?.content}</div>
                    <div className="-mx-2 mt-[10px] flex flex-wrap gap-2 pl-[32px] [&_.ant-image-mask]:rounded">
                      {comment?.imageUrls?.length > 0 && (
                        <Image.PreviewGroup>
                          {comment?.imageUrls?.map((image) => (
                            <Image
                              key={image}
                              width={75}
                              height={75}
                              className="rounded object-cover"
                              src={image}
                            />
                          ))}
                        </Image.PreviewGroup>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}

        <div className="h-[64px]" />
        <div className="z-100 fixed bottom-0 left-1/2 flex w-full max-w-lg -translate-x-1/2 transform flex-row items-center justify-around bg-white shadow-lg">
          <a
            href={"#"}
            onClick={(e) => {
              e.preventDefault()
              setSelected("information")
            }}
            className={`flex flex-col items-center justify-center whitespace-nowrap rounded-md px-2 py-2 font-semibold capitalize text-gray-400 ${selected === "information" ? "!text-primary-color" : ""}`}
          >
            <OrderedListOutlined className="mb-[4px] text-[20px]" />
            <span>Thông tin công việc</span>
          </a>
          <a
            href={"#"}
            onClick={(e) => {
              e.preventDefault()
              setSelected("comments")
            }}
            className={`flex flex-col items-center justify-center whitespace-nowrap rounded-md px-2 py-2 font-semibold capitalize text-gray-400 ${selected === "comments" ? "!text-primary-color" : ""}`}
          >
            <CommentOutlined className="mb-[4px] text-[20px]" />
            <span>Thảo luận</span>
          </a>
        </div>
      </div>
    </div>
  )
}

export default EmployeeTaskDetail
