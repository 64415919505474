export const PROJECT_TYPE = {
  agriculture: "Nông nghiệp",
  smartHome: "Nhà thông minh",
  education: "Giáo dục",
  medical: "Y tế",
  industrial: "Công nghiệp",
  others: "Khác",
  esg: "ESG",
}

export const API_URL = {
  development: "http://localhost:3004",
  stag: "https://stage-api.demeter.vn",
  production: "https://stag-api.demeter.vn",
  default: "https://stage-api.demeter.vn",
}

export const GRAPHQL_URL = (API_URL[process.env.REACT_APP_ENV] || API_URL["default"]) + "/graphql"

export const DEVICE_STATUS = {
  "-1": "Unknown",
  0: "Disconnected",
  1: "Connected",
}

export const ERROR = {
  BAD_REQUEST: {
    status: 400,
    message: "Bad Request",
  },
  UNKNOWN: {
    status: 520,
    message: "Unknown error",
  },
}

export const ROLE = {
  user: "Người dùng",
  employee: "Nhân viên",
  admin: "Quản trị viên",
  developer: "Nhà phát triển",
}

export const DEVICE_CONNECT_PROTOCOLS = {
  wifi: "Wi-Fi",
  wifiAndBluetooth: "Wi-Fi & Bluetooth",
  bluetoothMesh: "Bluetooth Mesh",
  bluetooth: "Bluetooth",
  ethernet: "Ethernet",
  twoThreeFourG: "2G/3G/4G",
  fiveG: "5G",
  other: "Other",
}

export const DEVICE_FUNCTION_TYPE = {
  property: "Property",
  event: "Event",
  action: "Action",
}

export const DEVICE_FUNCTION_TRANSFER_TYPE = {
  sendAndReport: "Send & Report",
  send: "Send only",
  report: "Report only",
}

export const BASIC_FILTER = [
  { label: "Creation Time - Descending", value: "createDesc" },
  { label: "Creation Time - Ascending", value: "createAsc" },
  { label: "Most updated", value: "updateDesc" },
]

export const DEVICE_FUNCTION_DATA_TYPE = {
  Value: "Value", // Apply to the data that can be linearly adjusted.  Ex:	Adjust the temperature, ranging from 20°C to 40°C.
  String: "String", // Transmit the data as a string.
  Date: "Date", // Date.
  Bool: "Bool", // on|off control.
  Enum: "Enum", // A custom finite set of values.
  Raw: "Raw", // Transmit the data in a raw format.
}

export const DEVICE_FUNCTION_SCALE = [0, 1, 2, 3]

export const DEVICE_STATUSES = {
  disconnected: "Disconnected",
  connected: "Connected",
}

export const TASK_PRIORITY = {
  common: "Thông thường",
  high: "Ưu tiên",
  important: "Quan trọng",
}

export const PRODUCT_UNIT = [
  "Bao",
  "Cái",
  "Cây",
  "Chai",
  "Gram",
  "Hộp",
  "Kg",
  "Lít",
  "Lô",
  "m2",
  "Tấn",
  "Trái",
  "Túi",
]

export const PHONE_REGEX = /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi

export const TASK_STATUS_COLOR = {
  completed: "var(--primary-color)",
  completedLate: "orange",
  processing: "warning",
  notStarted: "default",
  late: "error",
}

export const SUPPLY_KIND = {
  "Phân bón vô cơ": "Phân bón vô cơ",
  "Phân bón hữu cơ": "Phân bón hữu cơ",
  "Thuốc bảo vệ thực vật vô cơ": "Thuốc bảo vệ thực vật vô cơ",
  "Thuốc bảo vệ thực vật hữu cơ": "Thuốc bảo vệ thực vật hữu cơ",
  "Giống cây trồng": "Giống cây trồng",
  "Vật tư nông nghiệp khác": "Vật tư nông nghiệp khác",
}
