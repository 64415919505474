import { graphQLClient } from "./graphqlClient"
import Cookies from "js-cookie"
import { gql } from "graphql-request"
import { message as AntdMessage } from "antd"
import { ERROR } from "~/constants"
import { GRAPHQL_URL } from "~/constants"

const catchError = (error) => {
  let status = ERROR.UNKNOWN.status
  let message = ERROR.UNKNOWN.message

  const errors = error?.response?.errors
  if (errors) {
    status = errors?.[0]?.extensions?.response?.statusCode
    message = errors?.[0].message
  }

  if (status === 401) {
    logout()
  }

  AntdMessage.error(message)

  return Promise.reject({
    status,
    message,
  })
}

const serializeRequest = (request) => {
  return request.then((res) => res).catch(catchError)
}

// authentication
export const loginApi = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation login($params: LoginDto!) {
          login(loginUser: $params) {
            accessToken
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const registerApi = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation register($params: RegisterDto!) {
          register(registerUser: $params) {
            phone
            email
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const whoAmI = () =>
  serializeRequest(
    graphQLClient.request(gql`
      {
        getProfile {
          id
          firstName
          lastName
          phone
          email
          role
        }
      }
    `),
  )

export const logout = () => {
  Cookies.remove("token")
  Cookies.remove("user")
  window.location.replace("/")
  // window.location.reload();
}

export const getDashboard = () =>
  serializeRequest(
    graphQLClient.request(gql`
      query {
        projects {
          id
        }
        groups {
          id
        }
        devices {
          id
          name
          state
          group {
            id
            name
          }
        }
        products {
          id
        }
        procedures {
          id
          name
          startDate
          executionDays
        }
        stages {
          id
        }
        tasks {
          id
        }
        users {
          id
        }
        cameras {
          id
          sdViewUrl
          hdViewUrl
        }
        doneProceduresCount
        lateProceduresCount
      }
    `),
  )

//projects
export const createProject = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createProject($params: CreateProjectDto!) {
          createProject(project: $params) {
            id
            creatorId
            imageUrl
            projectType
            name
            location
            area
            startDate
            endDate
            updatedAt
            createdAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateProject = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateProject($id: String!, $update: UpdateProjectDto!) {
          updateProject(updateProject: $update, id: $id) {
            id
            creatorId
            users {
              id
              firstName
              lastName
              email
              phone
              role
            }
            groups {
              id
              imageUrl
              name
              location
              area
              startDate
              endDate
              updatedAt
              createdAt
              devices {
                id
                deviceId
                imageUrl
                deviceSerial
                state
                token
                status
                updatedAt
                createdAt
              }
            }
            devices {
              id
              deviceId
              groupId
              projectId
              imageUrl
              deviceSerial
              state
              token
              status
              updatedAt
              createdAt
            }
            imageUrl
            projectType
            name
            location
            area
            startDate
            endDate
            updatedAt
            createdAt
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

export const removeProject = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeProject($params: String!) {
          removeProject(id: $params)
        }
      `,
      {
        params,
      },
    ),
  )

export const getProject = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          project(id: $id) {
            id
            creatorId
            users {
              id
              firstName
              lastName
              email
              phone
              role
            }
            creator {
              id
              firstName
              lastName
              email
            }
            groups {
              id
              imageUrl
              name
              location
              area
              startDate
              endDate
              updatedAt
              createdAt
              devices {
                id
                deviceId
                imageUrl
                deviceSerial
                state
                token
                status
                updatedAt
                createdAt
              }
            }
            devices {
              id
              deviceId
              groupId
              projectId
              imageUrl
              deviceSerial
              state
              token
              status
              updatedAt
              createdAt
            }
            imageUrl
            projectType
            name
            location
            area
            startDate
            endDate
            updatedAt
            createdAt
          }
        }
      `,
      {
        id,
      },
    ),
  )

export const getProjects = (params, options) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query ($params: FetchDto, $options: FetchProjectDto) {
          projects(fetch: $params, options: $options) {
            id
            name
            location
            area
            creatorId
            users {
              id
              firstName
              lastName
            }
            creator {
              id
              firstName
              lastName
            }
          }
        }
      `,
      {
        params,
        options,
      },
    ),
  )

//groups
export const getGroups = (params, options) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query ($params: FetchDto, $options: FetchGroupDto) {
          groups(fetch: $params, options: $options) {
            id
            name
            location
            area
          }
        }
      `,
      {
        params,
        options,
      },
    ),
  )

export const createGroup = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createGroup($params: CreateGroupDto!) {
          createGroup(group: $params) {
            id
            projectId
            creatorId
            imageUrl
            name
            location
            area
            startDate
            endDate
            updatedAt
            createdAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateGroup = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateGroup($id: String!, $params: UpdateGroupDto!) {
          updateGroup(id: $id, updateGroup: $params) {
            id
            projectId
            creatorId
            imageUrl
            name
            location
            area
            startDate
            endDate
            updatedAt
            createdAt
          }
        }
      `,
      {
        id,
        params,
      },
    ),
  )

export const removeGroup = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeGroup($id: String!) {
          removeGroup(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )
//groups

//categories
export const createCategory = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createCategory($params: CreateCategoryDto!) {
          createCategory(createCategory: $params) {
            id
            name
            parentId
          }
        }
      `,
      {
        params,
      },
    ),
  )
export const updateCategory = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateCategory($params: UpdateCategoryDto!, $id: String!) {
          updateCategory(updateCategory: $params, id: $id) {
            id
            name
            parentId
          }
        }
      `,
      {
        id,
        params,
      },
    ),
  )

export const removeCategory = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeCategory($params: String!) {
          removeCategory(id: $params)
        }
      `,
      {
        params,
      },
    ),
  )

export const getCategory = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          category(id: $id) {
            id
            name
            parentId
          }
        }
      `,
      {
        id,
      },
    ),
  )

//deviceProfiles
export const createDeviceProfile = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createDeviceProfile($params: CreateDeviceProfileDto!) {
          createDeviceProfile(deviceProfile: $params) {
            id
            name
            imageUrl
            description
            protocol
            updatedAt
            createdAt
            layouts
            categories {
              id
              name
            }
            devices {
              id
              deviceSerial
            }
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const getDeviceProfile = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          deviceProfile(id: $id) {
            id
            name
            imageUrl
            description
            protocol
            updatedAt
            createdAt
            layouts
            categories {
              id
              name
            }
            devices {
              id
              deviceSerial
            }
            standardFunctions {
              id
              name
              key
              type
              transferType
              note
              parameters {
                dataType
                name
                key
                values
              }
              aliases {
                key
                value
              }
            }
          }
        }
      `,
      {
        id,
      },
    ),
  )

export const getDeviceProfiles = (params, options) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query ($params: FetchDto) {
          deviceProfiles(fetch: $params) {
            id
            name
            standardFunctions {
              id
              name
              key
              type
              transferType
              note
              parameters {
                dataType
                name
                key
                values
              }
              aliases {
                key
                value
              }
            }
          }
        }
      `,
      {
        params,
        options,
      },
    ),
  )

export const updateDeviceProfile = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation ($id: String!, $params: UpdateDeviceProfileDto!) {
          updateDeviceProfile(id: $id, updateDeviceProfile: $params) {
            id
            name
            imageUrl
            description
            protocol
            updatedAt
            createdAt
            layouts
            categories {
              id
              name
            }
            devices {
              id
              deviceSerial
            }
            standardFunctions {
              id
              name
              key
              type
              transferType
              note
              parameters {
                dataType
                name
                key
                values
              }
              aliases {
                key
                value
              }
            }
          }
        }
      `,
      {
        id,
        params,
      },
    ),
  )

export const removeDeviceProfile = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation ($id: String!) {
          removeDeviceProfile(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

// devices

export const getDevice = (id, { fetch = { limit: 1, page: 1 }, options = {} } = {}) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!, $fetch: FetchDto, $options: FetchDeviceHistoryDto) {
          device(id: $id) {
            id
            deviceId
            settings
            deviceProfile {
              id
              name
              imageUrl
              description
              protocol
              updatedAt
              createdAt
              categories {
                id
                name
              }
            }
            functions {
              id
              name
              key
              note
              type
              transferType
              parameters {
                name
                key
                parameterType
                dataType
                values
              }
              aliases {
                key
                value
              }
            }
            creator {
              id
              firstName
              lastName
            }
            project {
              id
              name
              projectType
            }
            group {
              id
              name
            }
            histories {
              id
              deviceId
              payload
              changes
              createdAt
            }
            dailyReports {
              id
              deviceId
              payload
              changes
              createdAt
            }
            monthlyReports {
              id
              deviceId
              payload
              changes
              createdAt
            }
            imageUrl
            name
            keyAndCerts
            deviceSerial
            lastState
            token
            status
            layout
            updatedAt
            createdAt
          }
          deviceHistories(fetch: $fetch, options: $options) {
            payload
          }
        }
      `,
      {
        id,
        fetch,
        options: {
          deviceId: id,
          ...options,
        },
      },
    ),
  )

export const createDevice = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createDevice($params: CreateDeviceDto!) {
          createDevice(device: $params) {
            id
            deviceId
            settings
            deviceProfile {
              id
              name
              imageUrl
              description
              protocol
              updatedAt
              createdAt
              categories {
                id
                name
              }
            }
            functions {
              id
              name
              key
              note
              type
              transferType
              parameters {
                name
                key
                parameterType
                dataType
                values
              }
              aliases {
                key
                value
              }
            }
            creator {
              id
              firstName
              lastName
            }
            project {
              id
              name
            }
            group {
              id
              name
            }
            imageUrl
            keyAndCerts
            deviceSerial
            state
            token
            status
            updatedAt
            createdAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateDevice = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation ($id: String!, $params: UpdateDeviceDto!) {
          updateDevice(id: $id, updateDevice: $params) {
            id
            deviceId
            settings
            deviceProfile {
              id
              name
              imageUrl
              description
              protocol
              updatedAt
              createdAt
              categories {
                id
                name
              }
            }
            functions {
              id
              name
              key
              note
              type
              transferType
              parameters {
                name
                key
                parameterType
                dataType
                values
              }
              aliases {
                key
                value
              }
            }
            creator {
              id
              firstName
              lastName
            }
            project {
              id
              name
            }
            group {
              id
              name
            }
            imageUrl
            name
            keyAndCerts
            deviceSerial
            state
            token
            status
            layout
            updatedAt
            createdAt
          }
        }
      `,
      {
        id,
        params,
      },
    ),
  )

export const removeDevice = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation ($id: String!) {
          removeDevice(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

export const downloadDeviceKeyAndCerts = (deviceId) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($deviceId: String!) {
          downloadDeviceKeyAndCerts(deviceId: $deviceId)
        }
      `,
      {
        deviceId,
      },
    ),
  )

export const syncFunctions = (deviceId) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation ($deviceId: String!) {
          syncFunctions(deviceId: $deviceId) {
            id
            deviceId
            creatorId
            deviceProfile {
              id
              name
              imageUrl
              description
              protocol
              updatedAt
              createdAt
              categories {
                id
                name
              }
            }
            functions {
              id
              name
              key
              note
              type
              transferType
              parameters {
                name
                key
                parameterType
                dataType
                values
              }
              aliases {
                key
                value
              }
            }
            projectId
            groupId
            imageUrl
            keyAndCerts
            deviceSerial
            state
            token
            status
            updatedAt
            createdAt
          }
        }
      `,
      {
        deviceId,
      },
    ),
  )

export const syncLayout = (deviceId) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation ($deviceId: String!) {
          syncLayout(deviceId: $deviceId) {
            id
            deviceId
            creatorId
            layout
            deviceProfile {
              id
              name
              imageUrl
              description
              protocol
              updatedAt
              createdAt
              categories {
                id
                name
              }
            }
            functions {
              id
              name
              key
              note
              type
              transferType
              parameters {
                name
                key
                parameterType
                dataType
                values
              }
              aliases {
                key
                value
              }
            }
            projectId
            groupId
            imageUrl
            keyAndCerts
            deviceSerial
            state
            token
            status
            updatedAt
            createdAt
          }
        }
      `,
      {
        deviceId,
      },
    ),
  )
//users
export const createUser = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createUser($createUser: CreateUserDto!) {
          createUser(createUser: $createUser) {
            id
            email
          }
        }
      `,
      {
        createUser: params,
      },
    ),
  )

export const updateUser = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateUser($id: String!, $update: UpdateUserDto!) {
          updateUser(updateUser: $update, id: $id) {
            id
            email
            phone
            firstName
            lastName
            role
            region
            createdAt
            updatedAt
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

export const removeUser = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeUser($params: String!) {
          removeUser(id: $params)
        }
      `,
      {
        params,
      },
    ),
  )

export const getUser = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          user(userId: $id) {
            id
            email
            phone
            firstName
            lastName
            role
            region
            createdAt
            updatedAt
          }
        }
      `,
      {
        id,
      },
    ),
  )

export const getUsers = (params, options) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query ($params: FetchDto) {
          users(fetch: $params) {
            id
            firstName
            lastName
            role
          }
        }
      `,
      {
        params,
        options,
      },
    ),
  )

//device functions
export const createDeviceFunction = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createDeviceFunction($params: DeviceFunctionDto!) {
          createDeviceFunction(deviceFunction: $params) {
            name
            key
            type
            transferType
            note
            parameters {
              dataType
              name
              key
              values
            }
            aliases {
              key
              value
            }
            createdAt
            updatedAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateDeviceFunction = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateDeviceFunction($params: UpdateDeviceFunctionDto!, $id: String!) {
          updateDeviceFunction(updateDeviceFunction: $params, id: $id) {
            name
            key
            type
            transferType
            note
            parameters {
              dataType
              name
              key
              values
            }
            aliases {
              key
              value
            }
            createdAt
            updatedAt
          }
        }
      `,
      {
        id: id,
        params,
      },
    ),
  )

export const removeDeviceFunction = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeDeviceFunction($id: String!) {
          removeDeviceFunction(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

export const getDeviceFunction = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          deviceFunction(id: $id) {
            name
            key
            type
            transferType
            note
            parameters {
              parameterType
              dataType
              name
              key
              values
            }
            aliases {
              key
              value
            }
            createdAt
            updatedAt
          }
        }
      `,
      {
        id,
      },
    ),
  )

export const getDeviceFunctions = (fetch, options) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query deviceFunctions($fetch: FetchDto!, $options: FetchDeviceFunctionDto) {
          deviceFunctions(fetch: $fetch, options: $options) {
            id
            key
            name
            note
            type
            transferType
            parameters {
              name
              key
              parameterType
              dataType
              values
            }
            aliases {
              key
              value
            }
          }
        }
      `,
      {
        fetch,
        options,
      },
    ),
  )

export const revokeCertificate = (thingName) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation revokeCertificate($thingName: String!) {
          revokeCertificate(thingName: $thingName)
        }
      `,
      {
        thingName,
      },
    ),
  )

export const attachCertificate = (thingName) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation attachCertificate($thingName: String!) {
          attachCertificate(thingName: $thingName)
        }
      `,
      {
        thingName,
      },
    ),
  )

export const renewCertificate = (thingName) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation renewCertificate($thingName: String!) {
          renewCertificate(thingName: $thingName)
        }
      `,
      {
        thingName,
      },
    ),
  )

export const generateToken = (thingName) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation generateToken($thingName: String!) {
          generateToken(thingName: $thingName)
        }
      `,
      {
        thingName,
      },
    ),
  )

export const removeToken = (thingName) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeToken($thingName: String!) {
          removeToken(thingName: $thingName)
        }
      `,
      {
        thingName,
      },
    ),
  )

export const uploadImage = async (file) => {
  let data = new FormData()
  data.append(
    "operations",
    '{ "query": "mutation ($image: Upload!) { uploadImage(image: $image) { creatorId url } }", "variables": { "image": null } }',
  )
  data.append("map", '{ "0": ["variables.image"] }')
  data.append("0", file)

  try {
    const response = await fetch(GRAPHQL_URL, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
      body: data,
    })

    const responseData = await response.json()
    return responseData?.data?.uploadImage?.url
  } catch (error) {
    return catchError(error)
  }
}

export const uploadFirmware = async (file) => {
  let data = new FormData()
  data.append(
    "operations",
    '{ "query": "mutation ($file: Upload!) { uploadFirmware(file: $file) { id name creatorId url } }", "variables": { "file": null } }',
  )
  data.append("map", '{ "0": ["variables.file"] }')
  data.append("0", file)

  try {
    const response = await fetch(GRAPHQL_URL, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
      body: data,
    })

    const responseData = await response.json()
    return responseData?.data?.uploadFirmware
  } catch (error) {
    return catchError(error)
  }
}

//cameras
export const createCamera = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createCamera($params: CreateCameraDto!) {
          createCamera(camera: $params) {
            id
            name
            imageUrl
            hdViewUrl
            sdViewUrl
            project {
              id
              name
            }
            group {
              id
              name
            }
            creator {
              id
              firstName
              lastName
            }
            createdAt
            updatedAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateCamera = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateCamera($params: UpdateCameraDto!, $id: String!) {
          updateCamera(camera: $params, id: $id) {
            id
            name
            imageUrl
            hdViewUrl
            sdViewUrl
            project {
              id
              name
            }
            group {
              id
              name
            }
            creator {
              id
              firstName
              lastName
            }
            creatorId
            createdAt
            updatedAt
          }
        }
      `,
      {
        id: id,
        params,
      },
    ),
  )

export const removeCamera = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeCamera($id: String!) {
          removeCamera(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

export const getCamera = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          camera(id: $id) {
            id
            name
            imageUrl
            hdViewUrl
            sdViewUrl
            project {
              id
              name
            }
            group {
              id
              name
            }
            creator {
              id
              firstName
              lastName
            }
            creatorId
            createdAt
            updatedAt
          }
        }
      `,
      {
        id,
      },
    ),
  )

export const getCameras = (fetch, options) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query cameras($fetch: FetchDto!, $options: FetchCameraDto) {
          cameras(fetch: $fetch, options: $options) {
            id
            name
            imageUrl
            hdViewUrl
            sdViewUrl
            projectId
            groupId
            creatorId
            createdAt
            updatedAt
          }
        }
      `,
      {
        fetch,
        options,
      },
    ),
  )

//cameras

export const getDeviceFirmwares = (fetch, options) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query deviceFirmwares($fetch: FetchDto!, $options: FetchDeviceFirmwareDto) {
          deviceFirmwares(fetch: $fetch, options: $options) {
            id
            name
            firmwareVersion
            hardwareVersion
            description
            fileId
            file {
              id
              creatorId
              url
              name
            }
            creatorId
            createdAt
            updatedAt
          }
        }
      `,
      {
        fetch,
        options,
      },
    ),
  )
export const createDeviceFirmware = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createDeviceFirmware($params: CreateDeviceFirmwareDto!) {
          createDeviceFirmware(deviceFirmware: $params) {
            id
            name
            firmwareVersion
            hardwareVersion
            description
            fileId
            file {
              id
              creatorId
              url
              name
            }
            creatorId
            createdAt
            updatedAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const removeDeviceFirmware = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeDeviceFirmware($id: String!) {
          removeDeviceFirmware(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

export const getDeviceSettings = (deviceId) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query deviceSettings($deviceId: String!) {
          device(id: $deviceId) {
            settings
            notificationEnabled
            onlineNotification
            offlineNotification
          }
          deviceSettings(deviceId: $deviceId) {
            alarmEnabled
            scheduleEnabled
            alarms {
              id
              name
              repeat
              startTime
              endTime
              effectType
              enabled
              when
              deviceId
              conditions {
                functionKey
                operator
                value
              }
              tasks {
                setState
                notification
              }
            }
            schedules {
              id
              name
              type
              enabled
              repeat
              runTime
              time
              deviceId
              tasks {
                setState
                notification
              }
            }
          }
        }
      `,
      { deviceId },
    ),
  )

export const getDeviceSettingsJSON = (deviceId) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query deviceSettingsJSON($deviceId: String!) {
          deviceSettingsJSON(deviceId: $deviceId)
        }
      `,
      { deviceId },
    ),
  )

export const updateDeviceSettings = (deviceId, settings) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateDeviceSettings($deviceId: String!, $settings: String!) {
          updateDeviceSettings(deviceId: $deviceId, settings: $settings) {
            alarmEnabled
            scheduleEnabled
            alarms {
              id
              name
              repeat
              startTime
              endTime
              effectType
              enabled
              when
              conditions {
                functionKey
                operator
                value
              }
              tasks {
                setState
                notification
              }
            }
            schedules {
              id
              name
              type
              enabled
              repeat
              runTime
              time
              deviceId
              tasks {
                setState
                notification
              }
            }
          }
        }
      `,
      { deviceId, settings },
    ),
  )

export const updateDeviceSettingsJSON = (deviceId, settings) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateDeviceSettingsJSON($deviceId: String!, $settings: String!) {
          updateDeviceSettingsJSON(deviceId: $deviceId, settings: $settings)
        }
      `,
      { deviceId, settings },
    ),
  )

export const getSnapshots = (fetch, options) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query GetSnapshots($options: FetchSnapshotDto!, $fetch: FetchDto) {
          snapshots(options: $options, fetch: $fetch) {
            createdAt
            creatorId
            id
            snapshotableId
            snapshotableType
            url
            updatedAt
            capturedAt
          }
        }
      `,
      {
        fetch,
        options,
      },
    ),
  )

export const removeSnapshot = (id) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        mutation removeSnapshot($id: String!) {
          removeSnapshot(id: $id)
        }
      `,
      { id },
    ),
  )

export const uploadSnapshot = async (image, cameraId) => {
  let data = new FormData()
  data.append(
    "operations",
    `{ "query": "mutation uploadSnapshot($image: Upload!, $snapshot: CreateSnapshotDto!) { uploadSnapshot(snapshot: $snapshot, image: $image) { url snapshotableId snapshotableType } }", "variables": { "image": null, "snapshot": {  "snapshotableType": "camera", "snapshotableId": "${cameraId}"  } }}`,
  )
  data.append("map", '{ "0": ["variables.image"] }')
  data.append("0", image)

  try {
    const response = await fetch(GRAPHQL_URL, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
      body: data,
    })

    const responseData = await response.json()
    return responseData?.data?.uploadSnapshot?.url
  } catch (error) {
    return catchError(error)
  }
}

export const createSnapshot = (snapshot) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createSnapshot($snapshot: CreateSnapshotDto!) {
          createSnapshot(snapshot: $snapshot) {
            id
          }
        }
      `,
      {
        snapshot,
      },
    ),
  )

//product
export const createProduct = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createProduct($params: CreateProductDto!) {
          createProduct(product: $params) {
            id
            name
            imageUrls
            description
            updatedAt
            createdAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateProduct = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateProduct($id: String!, $update: UpdateProductDto!) {
          updateProduct(product: $update, id: $id) {
            id
            name
            imageUrls
            description
            updatedAt
            createdAt
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

export const removeProduct = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeProduct($id: String!) {
          removeProduct(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

export const getProduct = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          product(id: $id) {
            id
            name
            kind
            description
            userManual
            imageUrls
            packagingUnit
            barCode
            netWeight
            unit
            url
            userManual
            updatedAt
            createdAt
          }
        }
      `,
      {
        id,
      },
    ),
  )

export const getProducts = (params, options) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query ($params: FetchDto, $options: FetchProductDto) {
          products(fetch: $params, options: $options) {
            id
            name
            barCode
            kind
            imageUrls
            description
            updatedAt
            createdAt
          }
        }
      `,
      {
        params,
        options,
      },
    ),
  )

//procedures
export const getProcedures = (params = {}, options = {}) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query ($params: FetchDto, $options: FetchProcedureDto) {
          procedures(fetch: $params, options: $options) {
            id
            name
            description
            startDate
            updatedAt
            createdAt
          }
        }
      `,
      {
        params,
        options,
      },
    ),
  )

export const createProcedure = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createProcedure($params: CreateProcedureDto!) {
          createProcedure(procedure: $params) {
            id
            name
            description
            startDate
            updatedAt
            createdAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateProcedure = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateProcedure($id: String!, $update: UpdateProcedureDto!) {
          updateProcedure(procedure: $update, id: $id) {
            id
            name
            description
            startDate
            updatedAt
            createdAt
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

export const removeProcedure = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeProcedure($id: String!) {
          removeProcedure(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

export const getProcedure = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          procedure(id: $id) {
            id
            name
            description
            startDate
            executionDays
            creatorId
            project {
              id
              name
            }
            procedureStages {
              id
              stageId
              startDate
              completedAt
              note
              imageUrls
              stageTasks {
                id
                note
                imageUrls
                startedAt
                completedAt
                plannedStartedAt
                plannedCompletedAt
                startDate
                endDate
                procedureStageId
                assignedToId
                comments {
                  id
                  content
                  imageUrls
                  creator {
                    id
                    firstName
                    lastName
                  }
                  createdAt
                  updatedAt
                }
                assignedTo {
                  id
                  firstName
                  lastName
                  role
                }
                stageTaskSupplies {
                  id
                  quantity
                  unit
                  productAmount
                  price
                  note
                  supply {
                    id
                    name
                  }
                }
                task {
                  id
                  name
                  description
                  executionDays
                  spaceId
                  startDay
                  taskSupplies {
                    id
                    quantity
                    unit
                    supply {
                      id
                      name
                    }
                  }
                  priority
                  createdAt
                }
              }
              stage {
                id
                name
                description
              }
            }
            updatedAt
            createdAt
          }
        }
      `,
      {
        id,
      },
    ),
  )
//tasks
export const getTasks = (params = {}, options = {}) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query ($params: FetchDto, $options: FetchTaskDto) {
          tasks(fetch: $params, options: $options) {
            id
            name
            description
            startDay
            priority
            executionDays
            updatedAt
            createdAt
          }
        }
      `,
      {
        params,
        options,
      },
    ),
  )

export const createTask = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createTask($params: CreateTaskDto!) {
          createTask(task: $params) {
            id
            name
            description
            startDay
            executionDays
            updatedAt
            createdAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateTask = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateTask($id: String!, $update: UpdateTaskDto!) {
          updateTask(task: $update, id: $id) {
            id
            name
            description
            startDay
            priority
            updatedAt
            createdAt
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

export const removeTask = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeTask($id: String!) {
          removeTask(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

export const updateStageTask = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateStageTask($id: String!, $update: UpdateStageTaskDto!) {
          updateStageTask(stageTask: $update, id: $id) {
            id
            note
            startedAt
            completedAt
            imageUrls
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

//stages
export const getStages = (params = {}, options = {}) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query ($params: FetchDto, $options: FetchStageDto) {
          stages(fetch: $params, options: $options) {
            id
            name
            description
            updatedAt
            createdAt
          }
        }
      `,
      {
        params,
        options,
      },
    ),
  )

export const createStage = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createStage($params: CreateStageDto!) {
          createStage(stage: $params) {
            id
            name
            description
            updatedAt
            createdAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateStage = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateStage($id: String!, $update: UpdateStageDto!) {
          updateStage(stage: $update, id: $id) {
            id
            name
            description
            updatedAt
            createdAt
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

export const removeStage = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeStage($id: String!) {
          removeStage(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

export const getStage = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          stage(id: $id) {
            id
            name
            description
            updatedAt
            createdAt
          }
        }
      `,
      {
        id,
      },
    ),
  )

//traceability
export const getTraceabilities = (params = {}, options = {}) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query ($params: FetchDto, $options: FetchTraceabilityDto) {
          traceabilities(fetch: $params, options: $options) {
            date
            note
            productId
            product {
              id
              name
            }
            procedureId
            procedure {
              id
              name
            }
            batchId
            startNumber
            endNumber
            groupId
            group {
              id
              name
            }
            quantity
            unit
            manufactureDate
            expiredDate
            createdAt
            updatedAt
          }
        }
      `,
      {
        params,
        options,
      },
    ),
  )

export const createTraceability = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createTraceability($params: CreateTraceabilityDto!) {
          createTraceability(traceability: $params) {
            date
            note
            productId
            product {
              id
              name
            }
            procedureId
            procedure {
              id
              name
            }
            batchId
            startNumber
            endNumber
            groupId
            group {
              id
              name
            }
            quantity
            unit
            manufactureDate
            expiredDate
            createdAt
            updatedAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateTraceability = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateTraceability($id: String!, $update: UpdateTraceabilityDto!) {
          updateTraceability(traceability: $update, id: $id) {
            date
            note
            productId
            product {
              id
              name
            }
            procedureId
            procedure {
              id
              name
            }
            batchId
            startNumber
            endNumber
            groupId
            group {
              id
              name
            }
            quantity
            unit
            manufactureDate
            expiredDate
            createdAt
            updatedAt
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

export const removeTraceability = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeTraceability($id: String!) {
          removeTraceability(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

export const getTraceability = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          traceability(id: $id) {
            date
            note
            productId
            product {
              id
              name
              unit
              packagingUnit
              netWeight
              imageUrls
            }
            procedureId
            procedure {
              id
              name
              startDate
              procedureStages {
                stage {
                  id
                  name
                  description
                  imageUrls
                }
                stageTasks {
                  id
                  startedAt
                  completedAt
                  stageTaskSupplies {
                    id
                    quantity
                    unit
                    productAmount
                    price
                    supply {
                      id
                      name
                      producerName
                      brandName
                      features
                      url
                      description
                      imageUrls
                    }
                  }
                  task {
                    id
                    name
                    startDay
                    executionDays
                  }
                }
              }
            }
            space {
              businessCode
              businessName
              businessAddress
              businessPhone
              businessEmail
              businessWebsite
              description
              thumbnailUrl
              imageUrls
            }
            batchId
            startNumber
            endNumber
            groupId
            quantity
            manufactureDate
            expiredDate
            createdAt
            updatedAt
          }
        }
      `,
      {
        id,
      },
    ),
  )

//enterprise
export const getEnterprises = (params = {}, options = {}) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query ($params: FetchDto, $options: FetchEnterpriseDto) {
          enterprises(fetch: $params, options: $options) {
            id
            businessCode
            businessName
            businessAddress
            businessPhone
            businessEmail
            businessWebsite
            description
            thumbnailUrl
            imageUrls
            createdAt
            updatedAt
          }
        }
      `,
      {
        params,
        options,
      },
    ),
  )

export const createEnterprise = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createEnterprise($params: CreateEnterpriseDto!) {
          createEnterprise(enterprise: $params) {
            id
            businessCode
            businessName
            businessAddress
            businessPhone
            businessEmail
            businessWebsite
            description
            thumbnailUrl
            imageUrls
            createdAt
            updatedAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateEnterprise = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateEnterprise($id: String!, $update: UpdateEnterpriseDto!) {
          updateEnterprise(enterprise: $update, id: $id) {
            id
            businessCode
            businessName
            businessAddress
            businessPhone
            businessEmail
            businessWebsite
            description
            thumbnailUrl
            imageUrls
            createdAt
            updatedAt
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

export const removeEnterprise = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeEnterprise($id: String!) {
          removeEnterprise(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

export const getEnterprise = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          enterprise(id: $id) {
            id
            businessCode
            businessName
            businessAddress
            businessPhone
            businessEmail
            businessWebsite
            description
            thumbnailUrl
            imageUrls
            createdAt
            updatedAt
          }
        }
      `,
      {
        id,
      },
    ),
  )

//supply
export const getSupplies = (params = {}, options = {}) =>
  serializeRequest(
    graphQLClient.rawRequest(
      gql`
        query ($params: FetchDto, $options: FetchSupplyDto) {
          supplies(fetch: $params, options: $options) {
            id
            name
            producerName
            brandName
            features
            description
            url
            kind
            imageUrls
            createdAt
            updatedAt
          }
        }
      `,
      {
        params,
        options,
      },
    ),
  )

export const createSupply = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createSupply($params: CreateSupplyDto!) {
          createSupply(supply: $params) {
            id
            name
            producerName
            brandName
            features
            description
            url
            imageUrls
            createdAt
            updatedAt
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateSupply = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateSupply($id: String!, $update: UpdateSupplyDto!) {
          updateSupply(supply: $update, id: $id) {
            id
            name
            producerName
            brandName
            features
            description
            url
            imageUrls
            createdAt
            updatedAt
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

export const removeSupply = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation removeSupply($id: String!) {
          removeSupply(id: $id)
        }
      `,
      {
        id,
      },
    ),
  )

export const getSupply = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          supply(id: $id) {
            id
            name
            kind
            producerName
            brandName
            features
            description
            url
            imageUrls
            createdAt
            updatedAt
          }
        }
      `,
      {
        id,
      },
    ),
  )

// procedurestage
export const createProcedureStage = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createProcedureStage($params: CreateProcedureStageDto!) {
          createProcedureStage(createProcedureStage: $params) {
            id
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const updateProcedureStage = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateProcedureStage($id: String!, $update: UpdateProcedureStageDto!) {
          updateProcedureStage(procedureStage: $update, id: $id) {
            id
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

export const reorderProcedureStage = (id, params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation reorderProcedureStage($id: String!, $update: ReorderProcedureStageDto!) {
          reorderProcedureStage(reorderProcedureStage: $update, id: $id) {
            id
          }
        }
      `,
      {
        id: id,
        update: params,
      },
    ),
  )

export const createStageTask = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createStageTask($params: CreateStageTaskDto!) {
          createStageTask(createStageTask: $params) {
            id
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const getStageTask = (id) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        query ($id: String!) {
          stageTask(id: $id) {
            id
            note
            imageUrls
            startedAt
            completedAt
            plannedStartedAt
            plannedCompletedAt
            startDate
            endDate
            procedureStageId
            procedureStage {
              id
              procedure {
                id
                name
              }
              stage {
                id
                name
              }
            }
            comments {
              id
              content
              imageUrls
              creatorId
              creator {
                id
                firstName
                lastName
              }
              createdAt
              updatedAt
            }
            assignedToId
            assignedTo {
              id
              firstName
              lastName
              role
            }
            stageTaskSupplies {
              id
              quantity
              unit
              productAmount
              price
              note
              supply {
                id
                name
              }
            }
            task {
              id
              name
              description
              executionDays
              startDay
              imageUrls
              taskSupplies {
                id
                quantity
                unit
                supply {
                  id
                  name
                }
              }
              priority
              createdAt
            }
          }
        }
      `,
      {
        id,
      },
    ),
  )

export const currentStageTasks = () =>
  serializeRequest(
    graphQLClient.request(gql`
      query {
        currentStageTasks {
          id
          note
          imageUrls
          startedAt
          completedAt
          plannedStartedAt
          plannedCompletedAt
          startDate
          endDate
          procedureStageId
          assignedToId
          procedureStage {
            id
            procedure {
              id
              name
            }
            stage {
              id
              name
            }
          }
          assignedTo {
            id
            firstName
            lastName
            role
          }
          stageTaskSupplies {
            id
            quantity
            unit
            productAmount
            price
            note
            supply {
              id
              name
            }
          }
          task {
            id
            name
            description
            executionDays
            startDay
            taskSupplies {
              id
              quantity
              unit
              supply {
                id
                name
              }
            }
            priority
            createdAt
          }
        }
      }
    `),
  )

export const createComment = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation createComment($params: CreateCommentDto!) {
          createComment(comment: $params) {
            id
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const mySpace = () =>
  serializeRequest(
    graphQLClient.request(gql`
      query {
        mySpace {
          id
          businessCode
          businessName
          businessAddress
          businessPhone
          businessEmail
          businessWebsite
          description
          thumbnailUrl
          imageUrls
        }
      }
    `),
  )

export const updateSpace = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation updateSpace($params: UpdateSpaceDto!) {
          updateSpace(space: $params) {
            id
          }
        }
      `,
      {
        params,
      },
    ),
  )

export const cloneProcedure = (params) =>
  serializeRequest(
    graphQLClient.request(
      gql`
        mutation cloneProcedure($params: CloneProcedureDto!) {
          cloneProcedure(cloneProcedure: $params)
        }
      `,
      {
        params,
      },
    ),
  )
