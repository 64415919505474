import React from "react"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import Editor from "ckeditor5-custom-build"
import { uploadImage } from "~/services/apis"

function CustomEditor({ value, onChange, disabled = false }) {
  return (
    <CKEditor
      editor={Editor}
      disabled={disabled}
      data={value || ""}
      onChange={(_, editor) => {
        const data = editor.getData()

        return onChange?.(data)
      }}
      onReady={(editor) => {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return {
            upload: async () => {
              return { default: await uploadImage(await loader.file) }
            },
          }
        }
      }}
      config={{
        fontFamily: {
          options: ["Arial", "Courier New"],
        },
        mediaEmbed: {
          previewsInData: true,
        },
      }}
    />
  )
}

export default CustomEditor
